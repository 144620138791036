.sub-target-edit-item {
    transition: 0.4s;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border: 1px solid #f0f0f0;
    border-top: none;
    background-color: #e9f0fd;
}

.work-edit-item {
    transition: 0.4s;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border: 1px solid #f0f0f0;
    border-top: none;
}

.work-edit-item:hover {
    background-color: #f8f9fb;
}

.weight-number-sub-target {
    text-align: center;
    font-weight: 500;
}
