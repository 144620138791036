.target-item {
    background-color: #fff;
    transition: 0.4s;
    margin-bottom: 1px;
}

.header-target-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 18px 0;
    margin: -12px 0;
}

.target-item:hover {
    background-color: #e9f0fd;
}

.sub-target-item {
    padding: 14px 16px 14px 40px;
    background-color: #fff;
    transition: 0.4s;
    /*cursor: pointer;*/
    border-bottom: 1px solid #f0f0f0;
}

.sub-target-item-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
}

.sub-target-item .icon-star {
    color: #2f54eb;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -25px;
}

.sub-target-item:hover {
    background-color: #f8f9fb;
}

.header-kpi-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 12px 16px;
    background-color: #2c65ac;
    color: #fff;
    font-weight: 500;
}

.kpi-item {
    padding: 16px;
    border-radius: 6px;
    background-color: #f4f3f8;
    margin-bottom: 20px;
}

.title-kpi-list {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #2c65ac;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.title-kpi-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.weight-number-kpi {
    flex: 0 0;
    width: 211px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 42px;
    font-weight: 500;
    /*background-color: #fff;*/
    /*padding: 5px;*/
    /*box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;*/
    /*border-radius: 10px;*/
}

.summary-weight-value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
